var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"modelsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"ListIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Model specifications")])],1),_c('b-form-group',{attrs:{"label":"Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Type","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"type","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.typesList,"reduce":function (type) { return type.id; },"placeholder":"Select type","label":"name"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Manufacturer","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"manufacturer","vid":"manufacturer_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.manufacturersList,"reduce":function (manufacturer) { return manufacturer.id; },"placeholder":"Select manufacturer","label":"name"},model:{value:(_vm.form.manufacturer_id),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer_id", $$v)},expression:"form.manufacturer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Manufacturer Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"manufacturer_name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Manufacturer Name","autocomplete":"off"},model:{value:(_vm.form.manufacturer_name),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer_name", $$v)},expression:"form.manufacturer_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Product Code, SKU","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"sku","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Product Code, SKU","autocomplete":"off"},model:{value:(_vm.form.sku),callback:function ($$v) {_vm.$set(_vm.form, "sku", $$v)},expression:"form.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"System Board Model","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"system_board_model","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"System Board Model","autocomplete":"off"},model:{value:(_vm.form.system_board_model),callback:function ($$v) {_vm.$set(_vm.form, "system_board_model", $$v)},expression:"form.system_board_model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container mt-1"},[_c('b-form-group',{staticClass:"neighbouring-form-group input-with-checkbox switch-row",attrs:{"label":"","label-class":"label-class"}},[_c('p',[_vm._v("Apply IP Firewall Bridge:")]),_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"switch":"","inline":""},model:{value:(_vm.form.is_use_ip_firewall),callback:function ($$v) {_vm.$set(_vm.form, "is_use_ip_firewall", $$v)},expression:"form.is_use_ip_firewall"}})],1)],1)],1),_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"GlobeIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Ports")])],1),_vm._l((_vm.ports),function(port,index){return _c('div',{key:index,staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Interface","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"interface","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Interface","autocomplete":"off"},model:{value:(port.interface),callback:function ($$v) {_vm.$set(port, "interface", $$v)},expression:"port.interface"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"neighbouring-form-group with-button"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Usage","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"usage","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.portsTypes,"placeholder":"Select usage"},model:{value:(port.usage),callback:function ($$v) {_vm.$set(port, "usage", $$v)},expression:"port.usage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"remove-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.ports.splice(index, 1)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","width":"20","height":"20","color":"#F0554E"}})],1)],1)],1)}),_c('div',{staticClass:"fullWidthButton"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.ports.push({ interface: '', usage: '' })}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","width":"20","height":"20","color":"#F0554E"}}),_vm._v(" Add another port ")],1)])],2),_c('div',{staticClass:"section-block  mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"FileIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Config")])],1),_c('b-form-group',{attrs:{"label":"","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Model Name","vid":"list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"upload-file",attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".conf, .cfg","state":Boolean(_vm.form.file)},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }