<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-overlay
              :show="loading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="ListIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Network specifications</h2>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Vlan Name"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      :rules="form.is_default ? 'max:255' : 'required|max:255'"
                    >
                      <b-form-input
                        v-model="form.name"
                        :disabled="form.is_default"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter vlan name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Vlan ID No"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Vlan ID"
                      vid="vlan_id"
                      :rules="form.is_default ? 'max:255' : 'required|max:255|between:2,4094'"
                    >
                      <b-form-input
                        v-model="form.vlan_id"
                        :disabled="form.is_default"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter vlan ID number"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Interface Address"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interface Address"
                      vid="interface_ip"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Enter interface address"
                        autocomplete="off"
                        :onchange="(value) => form.interface_ip = value"
                        :model="form.interface_ip"
                        inputs-id="interface_ip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Subnet Mask"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Subnet Mask"
                      vid="subnet_mask"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Enter subnet address"
                        autocomplete="off"
                        :onchange="(value) => form.subnet_mask = value"
                        :model="form.subnet_mask"
                        inputs-id="subnet_mask"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="!(form.is_default && !form.comment)"
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Comment"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="comment"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.comment"
                        :disabled="form.is_default"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter your comment"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </b-overlay>
            <b-overlay
              :show="loading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="GlobeIcon"
                    width="24"
                    height="24"
                  />
                  <h2>{{ form.is_default ? 'Vlan Ports' : 'Ports' }}</h2>
                </div>

                <b-form-group
                  label="Tagged ports"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tagged_ports"
                  >
                    <multiselect
                      v-model="form.tagged_ports"
                      :options="portsOption"
                      :disabled="form.is_default"
                      multiple
                      placeholder="Select tagged ports"
                      class="multiselect with-remove"
                      :searchable="false"
                      label="fullName"
                      @select="onSelectPort"
                      @remove="(prop) => onDeselectPort(prop, 'tagged')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Untagged ports"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="untagged_ports"
                  >
                    <multiselect
                      v-model="form.untagged_ports"
                      :options="portsOption"
                      :disabled="form.is_default"
                      multiple
                      placeholder="Select tagged ports"
                      class="multiselect with-remove"
                      :searchable="false"
                      label="fullName"
                      @select="onSelectPort"
                      @remove="(prop) => onDeselectPort(prop, 'untagged')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p
                  v-if="form.is_default"
                  class="lan-error-message"
                >
                  Edit is not allowed for dynamic ports
                </p>
              </div>
            </b-overlay>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Update"
                :request="validationForm"
              />
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="savedLan"
      ref="savedLan"
      title="Success!"
      size="md"
      ok-title="Ok"
      ok-only
      centered
      @hidden="goBack"
      @ok="goBack"
    >
      <p>Your LAN is successfully updated.</p>
      <p>Please note: you might want to update your{{ ' ' }}
        <b-link
          :to="`/site/${siteId}/hardware/index?tab=2&subTab=dhcp_table`"
        >
          DHCP servers
        </b-link> as well</p>
    </b-modal>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BLink,
  BOverlay,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import Multiselect from 'vue-multiselect'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BLink,
    BFormInput,
    BFormIp,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    Multiselect,
    BOverlay,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      networkId: this.$route.params.networkId,
      form: {
        name: '',
        vlan_id: '',
        interface_ip: '',
        subnet_mask: '',
        comment: '',
        tagged_ports: [],
        untagged_ports: [],
      },
      portsOption: [],
      portsOrigin: [],

      loading: true,

      required,
      max,
    }
  },
  async created() {
    try {
      const { data } = await axios.get(`/site/${this.siteId}/controller`)
      const { ports } = data.data.controller.model
      const validatedPorts = ports.map(port => ({
        ...port,
        fullName: `${port.interface} - ${port.usage}`,
      }))
      this.portsOption = validatedPorts
      this.portsOrigin = validatedPorts
    } catch (err) {
      console.warn(err)
    }
    try {
      const { data } = await axios.get(`/site/${this.siteId}/network/setting/lan/${this.networkId}`)
      const { tagged_ports, untagged_ports } = data.data

      let validatedTaggedPorts = []
      let validatedUntaggedPorts = []
      // eslint-disable-next-line camelcase
      if (!tagged_ports) {
        validatedTaggedPorts = []
      } else {
        validatedTaggedPorts = tagged_ports.map(port => ({
          ...port,
          fullName: `${port.interface} - ${port.usage}`,
        }))

        validatedTaggedPorts.forEach(port => this.onSelectPort(port))
      }
      // eslint-disable-next-line camelcase
      if (!untagged_ports) {
        validatedUntaggedPorts = []
      } else {
        validatedUntaggedPorts = untagged_ports.map(port => ({
          ...port,
          fullName: `${port.interface} - ${port.usage}`,
        }))

        validatedUntaggedPorts.forEach(port => this.onSelectPort(port))
      }
      this.form = {
        ...data.data,
        tagged_ports: validatedTaggedPorts,
        untagged_ports: validatedUntaggedPorts,
      }
      this.loading = false
    } catch (err) {
      console.warn(err)
    }
  },
  methods: {
    onSelectPort(prop) {
      this.portsOption = this.portsOption.filter(port => prop.id !== port.id)
    },
    onDeselectPort(prop, type) {
      this.portsOption = [...this.portsOption, prop]
      if (type === 'untagged') {
        const newPorts = this.form.untagged_ports.filter(port => prop.id !== port.id)
        this.form.untagged_ports = [...new Map(newPorts.map(item => [item.id, item])).values()]
      } else {
        const newPorts = this.form.tagged_ports.filter(port => prop.id !== port.id)
        this.form.tagged_ports = [...new Map(newPorts.map(item => [item.id, item])).values()]
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          const payload = {
            name: this.form.name,
            vlan_id: this.form.vlan_id,
            interface_ip: this.form.interface_ip,
            subnet_mask: this.form.subnet_mask,
            comment: this.form.comment,
            tagged_ports: this.form.tagged_ports ? this.form.tagged_ports.map(port => port.id) : [],
            untagged_ports: this.form.untagged_ports ? this.form.untagged_ports.map(port => port.id) : [],
          }
          await axios.put(`site/${this.siteId}/network/setting/lan/${this.networkId}`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$refs.savedLan.show()
        } catch (error) {
          console.warn('error', error)
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            const untaggedPorts = Object.keys(fields).filter(field => field.startsWith('untagged_ports'))
            const taggedPorts = Object.keys(fields).filter(field => field.startsWith('tagged_ports'))

            const errors = {
              ...fields,
              untagged_ports: untaggedPorts.map(port => fields[port])[0],
              tagged_ports: taggedPorts.map(port => fields[port])[0],
            }

            this.$refs.modelsCreate.setErrors(errors)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.lan-error-message {
  font-size: 14px;
  color: rgb(237, 122, 122);
}
</style>
