<template>
  <b-card-code
    no-body
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      :title="`${recordToDelete.isPermanent ? 'Are you sure you want to permanently delete this record?' : 'Are you sure you want to delete this record?'}`"
      centered
      ok-title="Delete"
      @ok="deleteModalHandler"
    >
      <div>
        {{ recordToDelete.isPermanent ? 'Permanently deleted records cannot be recovered later.' : 'In case you might want to recover it later, you can enable the "With deleted" toggle to find it and either recover or permanently delete it.' }}
      </div>
    </b-modal>
    <b-row
      class="px-2 mb-2"
    >
      <b-col
        v-if="$can('create', 'sites')"
        order-md="2"
        order="1"
        class="text-right"
      >
        <div
          v-if="$can('delete', 'sites')"
          label-class="label-class"
          class="with-deleted-checkbox"
        >
          <span>With deleted</span>
          <b-form-checkbox
            class="mr-0"
            switch
            inline
            @change="setFilterWithTrashed"
          />
        </div>
        <b-button
          v-if="$can('additional-action-delete', 'sites')"
          variant="primary"
          class="mr-2"
          @click="toggleFilterOnlyTrashed"
        >
          <span v-if="onlyTrashed">Without deleted</span>
          <span v-else>Only deleted</span>
        </b-button>
        <b-button
          to="/sites/create"
          variant="primary"
        >
          Create
        </b-button>
      </b-col>

      <b-col
        md="6"
        order-md="1"
        order="2"
      >
        <b-form @submit.prevent="searchRows()">
          <p v-if="companyId">
            Company: <a
              :href="`/company-management/summary/${companyId}`"
            >
              {{ companyName }}
            </a>
          </p>
          <b-form-row class="align-items-end">
            <b-col>
              <feather-icon
                v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
                v-b-tooltip.hover
                icon="XIcon"
                title="Reset filters"
                size="24"
                class="text-body align-middle mr-25"
                @click="handleReset"
              />
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
    <table-mobile-solo
      :search="searchValue"
      :handle-search="handleMobileSearch"
      :mobile-search-fields="mobileSearchFields"
      :items="items"
      :fields="fields"
      :pagination="{
        pagination: pagination,
        perPage: perPage,
        currentPage: page,
        pageSizeOptions: pageSizeOptions,
        enabled: true,
        perPageChanged: perPageChanged,
        changePage: changePage,
      }"
      :rules="{
        country(value) {
          return value.addresses.country ? value.addresses.country.name : '-'
        },
        client(value) {
          return value.company ? value.company.name : '-'
        },
        city(value) {
          return value.addresses ? value.addresses.city : '-'
        },
        zipCode(value) {
          return value.addresses ? value.addresses.postcode : '-'
        },
      }"
      :key-value="'name'"
      :actions="[{
        label: 'Edit',
        type: 'primary',
        icon: 'Edit2Icon',
        visible: () => $can('view', 'sites'),
        click: ({id}) => redirectToEdit(id),
      }, {
        label: 'Delete',
        type: 'danger',
        icon: 'TrashIcon',
        visible: ({is_deleted}) => !is_deleted && $can('delete', 'sites'),
        click: ({id}) => deleteHandler(id),
      }, {
        label: 'Permanently delete',
        type: 'danger',
        icon: 'TrashIcon',
        visible: ({is_deleted}) => is_deleted && $can('delete', 'sites'),
        click: ({id}) => deleteHandler(id, true),
      }, {
        label: 'Recover',
        type: 'primary',
        icon: 'RefreshCcwIcon',
        visible: ({is_deleted}) => is_deleted && $can('delete', 'sites'),
        click: ({id}) => recoverRow(id),
      }]"
    />

    <b-card-body>

      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        :rows="items"
        :total-rows="pagination.total"
        class="sites-table hide-mobile-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        compact-mode
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        @on-column-filter="columnFilterFn"
        @on-sort-change="onSortChange"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('view', 'sites')"
                  @click="redirectToEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.is_deleted && $can('delete', 'sites')"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'sites')"
                  @click.prevent="deleteHandler(props.row.id, true)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Permanently delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'sites')"
                  @click.prevent="recoverRow(props.row.id)"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span>Recover</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-if="props.column.field === 'is_online'">
            <b-badge
              v-if="!props.row.is_online"
              pill
              variant="danger"
            >Offline</b-badge>
            <b-badge
              v-else
              pill
              variant="success"
            >Online</b-badge>
          </span>
          <span v-else-if="props.column.field === 'name'">
            <router-link :to="`/site/${props.formattedRow.id}/hardware/index`">
              {{ props.formattedRow.name }}
            </router-link>
          </span>
          <span v-else-if="props.column.field === 'company'">
            {{ props.formattedRow.company }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BFormRow,
  BSpinner,
  VBTooltip,
  BBadge,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import { ref, watch } from '@vue/composition-api'
import TableMobileSolo from '@/components/TableMobileSolo.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    TableMobileSolo,
    BFormCheckbox,
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BForm,
    BFormRow,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      searchValue: '',
      isLoading: true,
      uppdateRouteName: 'site-update',
      currentRoute: this.$route.path,

      recordToDelete: {
        id: null,
        isPermanent: false,
      },

      deleteErrorPopUpInfo: {},

      mobileSearchFields: {
        name: '',
        'company.name': '',
      },
      fields: [
        {
          field: 'id',
          label: 'Id',
          hidden: true,
        },
        {
          field: 'name',
          label: 'Name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'addresses.country.name',
          sortable: false,
          label: 'Country',
          mobileSpecial: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'addresses.city',
          label: 'Town / City',
          sortable: false,
          mobileSpecial: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'addresses.postcode',
          label: 'Zip / Post Code',
          mobileSpecial: 'zipCode',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'company.name',
          label: 'Client',
          hidden: this.isClient,
          mobileSpecial: 'client',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'email',
          label: 'Email',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'contact_name',
          label: 'Contact name',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'telephone',
          label: 'Phone Number',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'is_online',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'hardware') && !this.$can('delete', 'hardware'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        const company = this.getCompany(this.$route.query.companyId)
        this.companyId = this.$route.query.companyId
        this.companyName = company.name
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  computed: {
    ...mapState('sites', {
      items: 'items',
      pagination: 'pagination',
      statuses: 'statuses',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
    page() {
      return this.$route.query?.params || {}
    },

  },
  watch: {
    async $route(to) {
      const additionalPathname = to.fullPath.replace(to.path, '')
      if (!additionalPathname) {
        await this.clearAllData()
        this.getData()
        this.companyName = ''
        this.companyId = ''
      }
    },

    recordToDelete(val) {
      if (val.id) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },

    items() {
      this.fields = this.fields.map(item => {
        if (item.label === 'Status') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.statuses] } }
        }
        return item
      })
    },
  },
  async mounted() {
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.roles.includes('client')) {
      this.fields = this.fields.filter(item => item.field !== 'company')
    }
  },
  methods: {
    ...mapActions('sites', {
      fetchItems: 'getItems',
      deleteRecord: 'deleteItem',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      getCompany: 'getCompany',
      recoverRecord: 'recoverItem',
    }),
    ...mapMutations('sites', ['RESET_STATE']),
    async deleteHandler(id, isPermanent = false) {
      this.recordToDelete = {
        id,
        isPermanent,
      }
      // this.$refs.modalDelete.show()
      // const errorRes = await this.deleteRow(id)
      // this.deleteErrorPopUpInfo = errorRes
    },
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }

      this.columnFilterFn(search, true)
    },
    async deleteModalHandler() {
      const errorRes = await this.deleteRow(this.recordToDelete.id)
      this.deleteErrorPopUpInfo = errorRes
      this.recordToDelete = {
        id: null,
        isPermanent: false,
      }
    },
    redirectToEdit(id) {
      this.$router.push(`/sites/update/${id}`)
    },
    rowStyleClassFn(row) {
      return row.is_deleted ? 'deleted-class' : ''
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .vgt-table{
    .vgt-left-align, .vgt-right-align{
      vertical-align: middle !important;
    }
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
  .sites-table {
    .deleted-class {
      .vgt-checkbox-col {
        background-color: rgba(255, 0, 0, 0.185) !important;
          input{
            display: none;
          }
      }
      .vgt-left-align {
        background-color: rgba(255, 0, 0, 0.185) !important;
      }
    }
  }
</style>
