<template>
  <b-card
    no-body
    class="table-border-container"
  >
    <b-row
      class="mt-2 mb-2"
    >
      <b-col
        v-if="$can('create', 'licences')"
      >
        <div class="site-hardware-actions site-hardware-actions_management wifi-setting">
          <div class="wifi-setting-label no-gap">
            <p class="label">
              WiFi Controller Settings
            </p>
            <b-form-checkbox
              v-if="$can('controller-action', 'sites')"
              v-model="controllerStatusLocalValue"
              name="controller-status"
              switch
              :value="1"
              :unchecked-value="0"
              :disabled="isSettingsUpdating"
              @change="handleStatusUpdate"
            />

            <b-spinner
              v-if="isSettingsUpdating"
              variant="primary"
              label="Spinning"
              small
            />
          </div>
          <div
            class="double-button actions-wifi-settings"
          >
            <b-button
              variant="primary"
              @click="handleAddNetwork"
            >
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-25"
              />
              Add Network
            </b-button>
            <b-dropdown
              toggle-class="more-dropdown-transparent"
              dropdown
              right
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle"
                  color="#F0554E"
                />
              </template>
              <b-dropdown-item
                @click="handlePushItem"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  size="20"
                  class="mr-50"
                />
                <span>Push to</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="handlePullItem"
              >
                <feather-icon
                  icon="DownloadCloudIcon"
                  size="20"
                  class="mr-50"
                />
                <span>Pull from</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="handleReProvisionItem"
              >
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="mr-50"
                />
                <span>Re-Provision Site</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card-body>
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        compact-mode
        :rows="items"
        mode="remote"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span class="d-flex justify-content-center">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.wifi-settings-modal
                  @click.prevent="handleQRCodeSelect(props.row.id)"
                >
                  <feather-icon
                    icon="GridIcon"
                    class="mr-50"
                  />
                  <span>QR Code</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.wifi-settings-modal
                  @click.prevent="handleEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.wifi-settings-modal
                  @click.prevent="handleDelete(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span
            v-if="props.column.field === 'password'"
          >
            <div>
              <div
                class="password-wifi-container"
              >
                <div
                  v-if="isPasswordVisible(props.row.id + '2g')"
                >
                  {{ props.row.g2.password }}
                </div>
                <span
                  v-else
                >
                  <input
                    type="password"
                    value="****************"
                    class="password-wifi-placeholder"
                    readonly
                  >
                </span>
                <feather-icon
                  :icon="isPasswordVisible(props.row.id + '2g') ? 'EyeOffIcon' : 'EyeIcon'"
                  class="cursor-pointer ml-1"
                  @click="togglePasswordVisibility(props.row.id + '2g')"
                />
              </div>
              <div
                v-if="!props.row.same_as_2g"
                class="divider"
              />
              <div
                v-if="!props.row.same_as_2g"
                class="mb-0 password-wifi-container"
                style="margin-top: 1rem;"
              >
                <div
                  v-if="isPasswordVisible(props.row.id + '5g')"
                >
                  {{ props.row.g5.password }}
                </div>
                <span
                  v-else
                >
                  <input
                    type="password"
                    class="password-wifi-placeholder"
                    value="****************"
                    readonly
                  >
                </span>
                <feather-icon
                  :icon="isPasswordVisible(props.row.id + '5g') ? 'EyeOffIcon' : 'EyeIcon'"
                  class="cursor-pointer ml-1"
                  :class="{ 'password-wifi': isPasswordVisible(props.row.id + '5g') }"
                  @click="togglePasswordVisibility(props.row.id + '5g')"
                />
              </div>
            </div>
          </span>
          <span v-else-if="props.column.field === 'is_hidden'">
            {{ props.row.is_hidden ? 'false' : 'true' }}
          </span>
          <span v-else-if="props.column.field === 'ssid'">
            {{ props.row.same_as_2g ? props.row.ssid : '' }}
            <span v-if="!props.row.same_as_2g">
              <p>{{ props.row.g2.ssid }}</p>
              <div
                v-if="!props.row.same_as_2g"
                class="divider"
              />
              <p class="mb-0">{{ props.row.g5.ssid }}</p>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'disabled'"
          >
            <b-form-checkbox
              v-model="props.formattedRow[props.column.field]"
              name="disabled"
              value="false"
              switch
              inline
              @change="changeStatus(props.formattedRow)"
            />
          </span>
          <span
            v-else-if="props.column.field === 'master'"
          >
            <span v-if="props.formattedRow[props.column.field]">
              Master
            </span>
            <span v-else>
              Slave
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
    <qr-code
      :site-id="siteId"
      :wifi-id="selectedWiFiId"
      :handle-close="() => { this.selectedWiFiId = '' }"
      :same-as2g="sameAs2g"
    />
    <b-modal
      id="modalDeleteLoader"
      ref="modalDeleteLoader"
      :title="`${modalTitle}...`"
      hide-footer
      centered
      hide-close
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="sm"
    >
      <div class="d-flex justify-content-center align-items-center mt-2 mb-2">

        <b-spinner
          variant="primary"
          label="Spinning"
          style="width: 3rem; height: 3rem;"
          huge
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BCardBody, BFormCheckbox, BSpinner, BDropdown, BDropdownItem, BRow, BCol, BButton, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '../../../axios-resolver'
import QrCode from './QrCode.vue'

export default {
  components: {
    QrCode,
    BCard,
    BCardBody,
    BFormCheckbox,
    BSpinner,
    BDropdown,
    BRow,
    BCol,
    BButton,
    BDropdownItem,
    BModal,
    VueGoodTable,
  },
  props: {
    siteId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      isSettingsUpdating: false,
      controllerStatusLocalValue: false,
      selectedWiFiId: '',
      sameAs2g: false,
      modalTitle: '',
      showedPasswords: [],
      fields: [
        { field: 'hw_id', hidden: true },
        {
          field: 'name',
          label: 'Network Name',
          sortable: false,
        },
        {
          field: 'ssid',
          label: 'SSID',
          sortable: false,
        },
        {
          field: 'encryption',
          label: 'Encryption',
          sortable: false,
        },
        {
          field: 'password',
          label: 'Password',
          sortable: false,
          width: '250px',
        },
        {
          field: 'is_hidden',
          label: 'SSID Visible',
          sortable: false,
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
    }
  },
  computed: {
    ...mapState('wifi', {
      items: 'items',
      controller_status: 'controller_status',
    }),
  },
  async created() {
    await this.fetchItems()
    this.controllerStatusLocalValue = this.controller_status ? 1 : 0
  },
  methods: {
    ...mapActions('wifi', [
      'getItems',
      'updateItemStatus',
      'pullItem',
      'pushItem',
      'reProvisionItem',
    ]),
    togglePasswordVisibility(id) {
      if (this.showedPasswords.includes(id)) {
        this.showedPasswords = this.showedPasswords.filter(item => item !== id)
      } else {
        this.showedPasswords.push(id)
      }
    },
    isPasswordVisible(id) {
      return this.showedPasswords.includes(id)
    },
    handleEdit(id) {
      this.$router.push({
        name: 'site-summary-edit-wifi',
        params: {
          id: +this.$route.params.id,
          wifiId: +id,
        },
      })
    },
    async fetchItems() {
      try {
        await this.getItems(this.siteId)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    handleAddNetwork() {
      this.$router.push({
        name: 'site-summary-add-wifi',
        params: {
          id: +this.$route.params.id,
        },
      })
    },
    handleQRCodeSelect(id) {
      this.selectedWiFiId = id

      this.sameAs2g = this.items.find(item => item.id === id).same_as_2g
    },
    async handleStatusUpdate() {
      try {
        this.isSettingsUpdating = true
        await this.updateItemStatus({ siteId: this.siteId, payload: { value: this.controllerStatusLocalValue } })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { message } = error.response.data

        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'BellIcon',
            variant: 'danger',
            message,
          },
        })
      } finally {
        this.isLoading = false
        this.isSettingsUpdating = false
      }
    },
    async handleDelete(id) {
      try {
        this.modalTitle = 'Deleting'
        this.$refs.modalDeleteLoader.show()
        await axios.delete(`/site/${this.siteId}/wifi/setting/${id}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.fetchItems()
      } catch (error) {
        console.warn(error)
      } finally {
        this.$refs.modalDeleteLoader.hide()
        this.isLoading = false
      }
    },
    async handlePushItem() {
      try {
        this.modalTitle = 'Pushing'
        this.$refs.modalDeleteLoader.show()
        await this.pushItem(this.siteId)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.$refs.modalDeleteLoader.hide()
        this.isLoading = false
      }
    },
    async handlePullItem() {
      try {
        this.modalTitle = 'Pulling'
        this.$refs.modalDeleteLoader.show()
        await this.pullItem(this.siteId)
        await this.fetchItems()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.$refs.modalDeleteLoader.hide()
        this.isLoading = false
      }
    },
    async handleReProvisionItem() {
      try {
        await this.reProvisionItem(this.siteId)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { message } = error.response.data

        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'BellIcon',
            variant: 'danger',
            message,
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
