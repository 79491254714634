<template>
  <div class="chart-container">
    <div>
      <p>{{ title }}:

        <span
          :style="{
            color: spanColor,
          }"
        >
          {{ title === 'RSRQ' ? rawTraffic / 10 : rawTraffic }}
        </span>
        {{ unit }}
      </p>
      <canvas
        :id="id"
        width="400"
        height="120"
      />
    </div>
    <div
      v-if="rawTraffic === 0"
      class="chart-na"
    >
      <h4>N/A</h4>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

const GRAPH_MIN_MAX = {
  RSSI: {
    min: -100,
    max: -50,
  },
  RSRP: {
    min: -120,
    max: -60,
  },
  SINR: {
    min: -10,
    max: 40,
  },
  RSRQ: {
    min: -40,
    max: 0,
  },
}

export default {
  name: 'Chart',
  props: {
    id: {
      required: true,
      type: String,
    },
    traffic: {
      required: true,
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    rawTraffic: {
      type: Number || String,
      default: 0,
    },
  },
  data() {
    return {
      chart: null,
      spanColor: '',
    }
  },
  watch: {
    traffic() {
      this.updateChartData()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    getSignalStrength(label, value) {
      switch (label) {
        case 'RSSI':
          if (value >= -65) {
            return 'excellent'
          }
          if (value >= -75) {
            return 'good'
          }
          if (value >= -85) {
            return 'satisfactory'
          }
          if (value >= -95) {
            return 'poor'
          }
          return 'no signal'
        case 'RSRP':
          if (value >= -80) {
            return 'excellent'
          }
          if (value >= -90) {
            return 'good'
          }
          if (value >= -100) {
            return 'fair'
          }
          return 'poor'
        case 'SINR':
          if (value >= 25) {
            return 'excellent'
          }
          if (value >= 15) {
            return 'good'
          }
          if (value >= 5) {
            return 'fair'
          }
          return 'poor'
        case 'RSRQ':
          if (value >= -10) {
            return 'excellent'
          }
          if (value >= -15) {
            return 'good'
          }
          if (value >= -20) {
            return 'fair'
          }
          return 'poor'
        default:
          return ''
      }
    },

    initChart() {
      const labels = []
      for (let count = 0; count <= 15; count += 1) {
        labels.push(count)
      }
      const config = {
        type: 'bar',
        data: {
          labels: [...labels],
          datasets: [
            {
              label: this.title,
              backgroundColor: '#006400',
              borderColor: '#006400',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
            {
              label: this.title,
              backgroundColor: '#008000',
              borderColor: '#008000',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
            {
              label: this.title,
              backgroundColor: '#F6BE00',
              borderColor: '#F6BE00',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
            {
              label: this.title,
              backgroundColor: '#FF0000',
              borderColor: '#FF0000',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
            {
              label: this.title,
              backgroundColor: '#808080',
              borderColor: '#808080',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [{
              type: 'realtime',
            }],
            y1: {
              // eslint-disable-next-line no-nested-ternary
              min: this.handleMinValue(),
              max: this.handleMaxValue(),
              display: true,
              ticks: {
                stepSize: 10,
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
            },
            title: {
              display: true,
              position: 'top',
              text: this.title,
              callbacks: {
                title() {
                  return ''
                },
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title() {
                  return ''
                },
                label(context) {
                  if (['RSSI', 'RSRP', 'RSRQ'].includes(context.dataset.label)) {
                    let value = context.raw

                    if (context.dataset.label === 'RSSI') {
                      value = Math.abs(value) - Math.abs(GRAPH_MIN_MAX.RSSI.min)
                    }
                    if (context.dataset.label === 'RSRP') {
                      value = Math.abs(value) - Math.abs(GRAPH_MIN_MAX.RSRP.min)
                    }
                    if (context.dataset.label === 'RSRQ') {
                      value = Math.abs(value) - Math.abs(GRAPH_MIN_MAX.RSRQ.min)
                    }
                    return `${context.dataset.label}: ${value}`
                  }
                  return `${context.dataset.label}: ${context.parsed.y}`
                },
              },
            },
          },
        },
        plugins: [{
          afterLayout(chart) {
            chart.legend.legendItems.forEach(
              label => {
                const customLabel = `${label.text}`
                return customLabel
              },
            )
          },
        }],
      }
      this.chart = new Chart(
        window.document.getElementById(this.id),
        config,
      )
      this.updateChartData()
      // setInterval(() => {
      //  this.chart.data.datasets.forEach(dataset => {
      //    dataset.data.push(Math.random() * 30)
      //    if (dataset.data.length > 30) {
      //      dataset.data.shift()
      //    }
      //  })
      //  this.chart.update()
      // }, 2000)
    },
    handleMaxValue() {
      if (this.title === 'RSSI') {
        return 50
      }
      if (this.title === 'RSRP') {
        return 60
      }
      if (this.title === 'SINR') {
        return GRAPH_MIN_MAX.SINR.max
      }
      if (this.title === 'RSRQ') {
        return 40
      }
      return 0
    },
    handleMinValue() {
      if (this.title === 'SINR') {
        return GRAPH_MIN_MAX.SINR.min
      }
      return 0
    },
    changeValueToGraph(value) {
      if (this.title === 'RSSI') {
        const RB = Math.abs(GRAPH_MIN_MAX.RSSI.min) - Math.abs(GRAPH_MIN_MAX.RSSI.max)
        return Math.abs(RB) - (Math.abs(value) - Math.abs(GRAPH_MIN_MAX.RSSI.max))
      }
      if (this.title === 'RSRP') {
        const RB = Math.abs(GRAPH_MIN_MAX.RSRP.min) - Math.abs(GRAPH_MIN_MAX.RSRP.max)
        return Math.abs(RB) - (Math.abs(value) - Math.abs(GRAPH_MIN_MAX.RSRP.max))
      }
      if (this.title === 'RSRQ') {
        const RB = Math.abs(GRAPH_MIN_MAX.RSRQ.min) - Math.abs(GRAPH_MIN_MAX.RSRQ.max)
        return Math.abs(RB) - (Math.abs(value) - Math.abs(GRAPH_MIN_MAX.RSRQ.max))
      }
      return value
    },
    updateChartData() {
      let isOverflow = false

      Object.values(this.traffic).forEach(traffic => {
        const datasetExcellent = this.chart.data.datasets[0]
        const datasetGood = this.chart.data.datasets[1]
        const datasetFair = this.chart.data.datasets[2]
        const datasetPoor = this.chart.data.datasets[3]
        const datasetNoSignal = this.chart.data.datasets[4]

        // shift when more then 15 data
        if (datasetExcellent.data.length > 15) {
          isOverflow = true
        }

        // ticks callback update

        this.chart.options.scales.y1.ticks.callback = value => {
          if (this.title === 'RSSI') {
            return value + GRAPH_MIN_MAX.RSSI.min
          }
          if (this.title === 'RSRP') {
            return value + GRAPH_MIN_MAX.RSRP.min
          }
          if (this.title === 'RSRQ') {
            return value + GRAPH_MIN_MAX.RSRQ.min
          }
          return value
        }

        // tooltip label

        // this.chart.options.plugins.tooltip.callbacks.label = context => {
        //   const label = context.dataset.label || ''
        //   if (context.dataset.label === 'SINR') {
        //     return label.concat(': ', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ` ${this.unit}`)
        //   }
        //   return label.concat(': -', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ` ${this.unit}`)
        // }

        // RSSI
        // excellent >=-65
        // good -65 to -75
        // satisfactory -75 to -85
        // poor -85 to -95
        // no signal -95 and below

        // RSRP
        // more than -80 is excellent,
        // between -80 and -90 good,
        // between -90 and -100 fair,
        // below -100 poor

        // SINR
        // Excellent - 25 or higher - Very strong signal relative to background noise,
        // Good - 15 to 25 - Strong signal, good performance for most activities.
        // Fair - 5 to 15 - Moderate signal, may experience reduced quality.
        // Poor - Below 5 - Weak signal, impacted by noise and interference.

        // RSRQ
        // Excellent -10 to -5 = High-quality signal with minimal interference.
        // Good -15 to -10 = Good quality signal, suitable for most uses.
        // Fair -20 to -15 = Acceptable quality, but may experience some degradation in performance.
        // Poor Below -20

        const value = this.title === 'RSRQ' ? traffic / 10 : traffic

        if (value === 0) {
          datasetExcellent.data.push(null)
          datasetGood.data.push(null)
          datasetFair.data.push(null)
          datasetPoor.data.push(null)
          datasetNoSignal.data.push(null)

          this.spanColor = '#000000'
          return
        }

        const signalStrength = this.getSignalStrength(this.title, value)
        const graphValue = this.changeValueToGraph(value)

        switch (signalStrength) {
          case 'excellent':
            datasetExcellent.data.push(graphValue)
            datasetGood.data.push(null)
            datasetFair.data.push(null)
            datasetPoor.data.push(null)
            datasetNoSignal.data.push(null)

            this.spanColor = '#006400'
            break
          case 'good':
            datasetExcellent.data.push(null)
            datasetGood.data.push(graphValue)
            datasetFair.data.push(null)
            datasetPoor.data.push(null)
            datasetNoSignal.data.push(null)

            this.spanColor = '#008000'
            break
          case 'satisfactory':
          case 'fair':
            datasetExcellent.data.push(null)
            datasetGood.data.push(null)
            datasetFair.data.push(graphValue)
            datasetPoor.data.push(null)
            datasetNoSignal.data.push(null)

            this.spanColor = '#F6BE00'
            break
          case 'poor':
            datasetExcellent.data.push(null)
            datasetGood.data.push(null)
            datasetFair.data.push(null)
            datasetPoor.data.push(graphValue)
            datasetNoSignal.data.push(null)

            this.spanColor = '#FF0000'
            break
          case 'no signal':
            datasetExcellent.data.push(null)
            datasetGood.data.push(null)
            datasetFair.data.push(null)
            datasetPoor.data.push(null)
            datasetNoSignal.data.push(graphValue)

            this.spanColor = '#808080'
            break
          default:
            break
        }

        if (isOverflow) {
          datasetExcellent.data.shift()
          datasetGood.data.shift()
          datasetFair.data.shift()
          datasetPoor.data.shift()
          datasetNoSignal.data.shift()
        }
      })

      this.chart.update(isOverflow ? 'none' : null)
    },
  },
}
</script>

<style scoped>

</style>
