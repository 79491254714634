<template>
  <div class="position-relative">
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-row v-else>
      <b-col
        cols="12"
      >
        <b-card
          v-if="summary && summary.controller && !summary.controller.is_online"
          class="card-info border"
          style="border-radius:4px; background-color: #dd4b39;"
        >
          <h2
            class="m-2"
            style="color: #ffefef"
          >
            Controller Offline!
          </h2>
          <p
            class="mx-2"
            style="color: #ffefef"
          >
            Changes will not be saved or applied while the controller is offline
          </p>
        </b-card>
        <b-card
          v-else-if="summary && !summary.controller"
          class="card-info border"
          style="border-radius:4px; background-color: #cbac1f;"
        >
          <h2
            style="color:#020202"
            class="m-2"
          >
            You don't have any assigned controllers yet
          </h2>
        </b-card>
        <b-card
          v-else-if="isAccessPointUnlicensed"
          class="card-info border d-flex direction-row"
          style="border-radius:4px; background-color: rgb(255 244 244); border: 1px solid #F0554E !important"
        >
          <div class="d-flex align-items-center justify-content-between p-2">
            <h3
              style="color:#611613"
              class="m-0"
            >
              Warning: One of your access points on this site is unlicensed, please renew the license or install the required hardware.
            </h3>
            <b-button
              style="min-width: 130px"
              class="ml-2"
              variant="primary"
              @click="openUnlinccedAccessPoint"
            >
              Read More
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="12"
        class="ml-auto"
      >
        <b-card
          v-if="summary"
          class="w-30 p-0 card-info pb-1"
        >
          <b-row class="d-flex">
            <div class="d-flex justify-content-space-between w-100">
              <div
                class="d-flex w-100 flex-column"
              >
                <div
                  class="site-info-card-container"
                  :class="{'no-ip': !publicIP.level}"
                >
                  <div
                    class="site-info-card"
                    :class="{ 'expanded': expandedCard.address }"
                  >
                    <h2>
                      Site Address
                    </h2>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Address 1: </span>
                      <span>{{ summary.addresses.address1 }}</span>
                    </div>
                    <div
                      v-if="summary.addresses.address2"
                      class="d-flex align-items-center mr-1 mb-1"
                    >
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Address 2: </span>
                      <span>{{ summary.addresses.address2 }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="GlobeIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Town/ City: </span>
                      <span>{{ summary.addresses.city }}</span>
                    </div>
                    <div
                      v-if="summary.addresses.country"
                      class="d-flex align-items-center mr-1 mb-1"
                    >
                      <feather-icon
                        icon="GlobeIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Country: </span>
                      <span>{{ summary.addresses.country.name }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="MailIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Postcode: </span>
                      <span>{{ summary.addresses.postcode }}</span>
                    </div>
                    <button
                      class="site-info-card__expand"
                      @click="expand('address')"
                    >
                      <feather-icon icon="ChevronDownIcon" />
                    </button>
                  </div>
                  <div
                    class="site-info-card"
                    :class="{ 'expanded': expandedCard.contact }"
                  >
                    <h2>
                      Site Contact
                    </h2>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="UserIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Contact name: </span>
                      <span>{{ summary.contact_name }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="AtSignIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Email: </span>
                      <span>{{ summary.email }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="PhoneIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Phone: </span>
                      <span>{{ telephone }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <feather-icon
                        icon="PackageIcon"
                        class="mr-75"
                      />
                      <span class="mr-50 card-label-bold">Company: </span>
                      <b-link :to="`/company-management/summary/${summary.company.id}`">
                        {{ summary.company.name }}
                      </b-link>
                    </div>
                    <button
                      class="site-info-card__expand"
                      @click="expand('contact')"
                    >
                      <feather-icon icon="ChevronDownIcon" />
                    </button>
                  </div>
                  <div
                    v-if="controllerLicense.category"
                    class="site-info-card"
                    :class="{ 'expanded': expandedCard.controller }"
                  >
                    <h2>
                      Controller Licence
                    </h2>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Category: </span>
                      <span>{{ controllerLicense.category }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Level: </span>
                      <span>{{ controllerLicense.level }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Type: </span>
                      <span>{{ controllerLicense.type }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Status: </span>
                      <b-badge
                        pill
                        :variant="controllerLicense.iconType"
                      >
                        {{ controllerLicense.status }}
                      </b-badge>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Renewal date: </span>
                      <span
                        :class="{ 'red-text': controllerLicense.diffDays }"
                      >
                        {{ controllerLicense.diffDays || controllerLicense.renewal_date }}
                      </span>
                    </div>
                    <button
                      class="site-info-card__see-more"
                      @click="handleLicenceNavigate"
                    >
                      See more <feather-icon icon="ChevronRightIcon" />
                    </button>
                    <button
                      class="site-info-card__expand"
                      @click="expand('controller')"
                    >
                      <feather-icon icon="ChevronDownIcon" />
                    </button>
                  </div>
                  <div
                    v-if="publicIP.level"
                    class="site-info-card"
                    :class="{ 'expanded': expandedCard.publicIp }"
                  >
                    <h2>
                      Public IP Licence
                    </h2>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Level: </span>
                      <span>{{ publicIP.level }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Type: </span>
                      <span>{{ publicIP.type }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Status: </span>
                      <b-badge
                        pill
                        :variant="publicIP.iconType"
                      >
                        {{ publicIP.status }}
                      </b-badge>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Renewal date: </span>
                      <span>{{ publicIP.renewal_date }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">Enable Public IP: </span>

                      <b-form-checkbox
                        v-model="publicIP.is_enable"
                        switch
                        :disabled="!publicIP.can_enable || isPublicLicenceUpdating"
                        :value="true"
                        :unchecked-value="false"
                        @change="switchEnable"
                      />

                      <b-spinner
                        v-if="isPublicLicenceUpdating"
                        variant="primary"
                        label="Spinning"
                        small
                      />
                    </div>
                    <div class="d-flex align-items-center mr-1 mb-1">
                      <span class="mr-50 card-label-bold">IP Address: </span>
                      <span>{{ publicIP.ip }}</span>
                    </div>
                    <button
                      class="site-info-card__expand"
                      @click="expand('publicIp')"
                    >
                      <feather-icon icon="ChevronDownIcon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="12"
      >
        <div>
          <b-card
            v-if="summary"
            no-body
          >
            <b-tabs
              v-model="activeTab"
              card
              pills
              class="site-info-tabs"
            >
              <b-tab
                title="Hardware"
                active
                :disabled="isLicenceExpired"
                @click="resetOptions"
              >
                <b-row>
                  <b-col cols="12">
                    <HardwareTable />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <LanDetails
                      v-if="summary.lanSettings"
                      :details="summary.lanSettings"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                title="Licences"
                class="pt-0 pr-0"
                no-body
                title-item-class="dropdown-tab"
                @click="resetOptions"
              >
                <div class="table-border-container">
                  <LicenceTable :fetch-controller-licence="{fetchControllerLicence}" />
                </div>
              </b-tab>
              <b-tab
                title="Network"
                class="pt-0 pr-0"
                no-body
                :disabled="!(summary.controller && summary.controller.id)"
                title-item-class="dropdown-tab"
                @click="resetOptions('network')"
              >
                <template
                  v-slot:title
                >
                  <b-dropdown
                    id="dropdown-1"
                    :disabled="!(summary.controller && summary.controller.id) || isLicenceExpired"
                    text="Network"
                  >
                    <b-dropdown-item
                      v-for="item in networkOptions"
                      :key="item.value"
                      :value="item.value"
                      @click="activeNetworkOption = item.value"
                    >
                      {{ item.title }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-tab>
              <b-tab
                title="Firewall"
                class="pt-0 pr-0"
                no-body
                :disabled="!(summary.controller && summary.controller.id)"
                title-item-class="dropdown-tab"
                @click="resetOptions('firewall')"
              >
                <template
                  v-slot:title
                >
                  <b-dropdown
                    id="dropdown-1"
                    :disabled="!(summary.controller && summary.controller.id) || isLicenceExpired"
                    text="Firewall"
                  >
                    <b-dropdown-item
                      v-for="item in firewallOptions"
                      :key="item.value"
                      :value="item.value"
                      @click="activeFirewallOption = item.value"
                    >
                      {{ item.title }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-tab>
              <!-- TODO change v-if="summary_hardware.length" to v-if="summary.hardware && summary.hardware.id"-->
              <b-tab
                title="WiFi"
                class="pt-0 pr-0"
                no-body
                :disabled="!(summary.controller && summary.controller.id)"
                title-item-class="dropdown-tab"
                @click="resetOptions('wifi')"
              >
                <template
                  v-slot:title
                >
                  <b-dropdown
                    id="dropdown-1"
                    text="WiFi"
                    :disabled="!(summary.controller && summary.controller.id) || isLicenceExpired"
                  >
                    <b-dropdown-item
                      v-for="item in wifiOptions"
                      :key="item.value"
                      :value="item.value"
                      @click="activeWifiOption = item.value"
                    >
                      {{ item.title }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-tab>
              <b-tab
                lazy
                title="Stats"
                :disabled="!(summary.controller && summary.controller.id) || isLicenceExpired"
                @click="resetOptions"
              >
                <b-row>
                  <b-col cols="12">
                    <Interface :hardware-id="summary.controller ? summary.controller.id : null" />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <!-- TODO :hardware-id="summary_hardware.length > 0 ? summary_hardware[0].id : null" change to :hardware-id="summary.hardware ? summary.hardware.id : null"-->
            <WiFiSettings
              v-if="activeWifiOption === 'settings'"
              :site-id="siteId"
            />
            <BlockList
              v-if="activeWifiOption === 'block_list'"
              :hardware-id="summary.controller ? summary.controller.id : null"
            />
            <DhcpTable
              v-if="activeNetworkOption === 'dhcp_table'"
              :hardware-id="summary.controller ? summary.controller.id : null"
            />
            <DhcpLeases
              v-if="activeNetworkOption === 'active_leases'"
              :hardware-id="summary.controller ? summary.controller.id : null"
              :site-id="siteId"
            />
            <WanSettings
              v-if="activeNetworkOption === 'wan_settings'"
              :hardware-id="summary.controller ? summary.controller.id : null"
              :site-id="siteId"
            />
            <AccessPoints
              v-if="activeWifiOption === 'access_points'"
              :site-id="siteId"
            />
            <AccessControl
              v-if="activeWifiOption === 'access_control'"
              :site-id="siteId"
            />
            <LanSettings
              v-if="activeNetworkOption === 'lan_settings'"
              :hardware-id="summary.controller ? summary.controller.id : null"
              :site-id="siteId"
            />
            <DhcpReservations
              v-if="activeNetworkOption === 'dhcp_static_reservations'"
              :hardware-id="summary.controller ? summary.controller.id : null"
            />
            <RegisteredDevices
              v-if="activeWifiOption === 'registered_devices'"
              :site-id="siteId"
            />
            <AddressListFirewall
              v-if="activeFirewallOption === 'firewall_address_lists'"
              :hardware-id="summary.controller ? summary.controller.id : null"
            />
            <FirewallNAT
              v-if="activeFirewallOption === 'firewall_nat'"
              :hardware-id="summary.controller ? summary.controller.id : null"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="licenceExpired"
      ref="licenceExpired"
      title="The licence is expired"
      size="md"
      ok-title="Ok"
      ok-only
      centered
      @hidden="closeExpireModal"
      @ok="closeExpireModal"
    >
      <p>The license <b>{{ isLicenceExpired }}</b> for your site is currently inactive. Please assign an active license to continue, or contact your provider to renew the existing license.</p>
    </b-modal>
    <b-modal
      v-if="controllerLicense"
      id="expireSoonModal"
      ref="expireSoonModal"
      title="License Expiration Notice"
      size="md"
      ok-title="Ok"
      ok-only
      centered
      @ok="closeIsExpiredSoon"
    >
      <p>Your site's license <b>{{ controllerLicense.number }}</b> will expire soon. To avoid any interruptions, please assign an active license or contact your provider to renew your current license.</p>
    </b-modal>
    <b-modal
      v-if="controllerLicense"
      id="unlicensedAccessPoint"
      ref="unlicensedAccessPoint"
      title="Warning: Unlicensed Access Point Detected"
      size="lg"
      ok-title="Ok"
      ok-only
      centered
      @ok="closeUnlinccedAccessPoint"
    >
      <p>There is an access point currently operating without a valid license. To ensure compliance and optimal performance, please take one of the following actions:</p>
      <ul>
        <li><b>Extend the License:</b> Renew or purchase the necessary license to continue using this access point.</li>
        <li><b>Add Related Hardware:</b> Ensure that the appropriate hardware is installed and configured for licensed use.</li>
      </ul>
      <p>For assistance or more information, please contact your network administrator or technical support team.</p>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BRow, BCol, BTabs, BTab, BDropdown, BDropdownItem, BSpinner, VBTooltip, BBadge, BFormCheckbox, BModal, BButton,
  BLink,
} from 'bootstrap-vue'
import parsePhoneNumber from 'libphonenumber-js'
import axios from '../axios-resolver'
// wifi
import WiFiSettings from './wifi/wifi-settings/WifiSettings.vue'
import BlockList from './wifi/BlockList.vue'
import RegisteredDevices from './wifi/RegisteredDevices.vue'
import AccessPoints from './wifi/access-points/AccessPoints.vue'
import AccessControl from './wifi/access-control/AccessControl.vue'

// status
import LanDetails from './status/LanDetails.vue'
import HardwareTable from './status/Index.vue'
import LicenceTable from './licence/Index.vue'
// interface
import Interface from './interface/Interface.vue'
// network
import DhcpTable from './network/DhcpTable.vue'
import DhcpLeases from './network/DhcpLeases.vue'
import DhcpReservations from './network/DhcpReservations.vue'
import LanSettings from './network/LanSettings.vue'
import WanSettings from './network/WanSettings.vue'
// firewall
import AddressListFirewall from './firewall/AddressList.vue'
import FirewallNAT from './firewall/FirewallNAT.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BFormCheckbox,
    BCol,
    BTabs,
    BBadge,
    BTab,
    BDropdown,
    WanSettings,
    BDropdownItem,
    BSpinner,
    WiFiSettings,
    BModal,
    BlockList,
    DhcpLeases,
    DhcpTable,
    LanDetails,
    AddressListFirewall,
    FirewallNAT,
    HardwareTable,
    DhcpReservations,
    LanSettings,
    LicenceTable,
    Interface,
    RegisteredDevices,
    AccessPoints,
    AccessControl,
    BButton,
    BLink,
  },
  data() {
    return {
      loading: false,
      isStatusesUpdating: false,
      isLicenceExpired: false,
      isAccessPointUnlicensed: null,
      expandedCard: {
        address: false,
        contact: false,
        controller: false,
        publicIp: false,
      },
      isPublicLicenceUpdating: false,
      activeTab: 0,
      controllerLicense: {
        category: '',
        level: '',
        type: '',
        status: '',
        renewal_date: '',
        diffDays: null,
      },
      publicIP: {
        level: '',
        type: '',
        status: '',
        renewal_date: '',
        ip: '',
        can_enable: null,
        is_enable: false,
      },
      wifiOptions: [
        {
          value: 'settings',
          title: 'Networks',
        },
        {
          value: 'access_points',
          title: 'Access Points',
        },
        {
          value: 'registered_devices',
          title: 'Registrations',
        },
      ],
      networkOptions: [
        {
          value: 'lan_settings',
          title: 'LAN Network  Settings',
        },
        {
          value: 'wan_settings',
          title: 'WAN Network Settings',
        },
        {
          value: 'dhcp_table',
          title: 'DHCP Servers',
        },
        {
          value: 'active_leases',
          title: 'Active Leases',
        },
        // {
        //   value: 'dhcp_static_reservations',
        //   title: 'DHCP Static Reservations',
        // },
      ],
      firewallOptions: [
        {
          value: 'firewall_address_lists',
          title: 'Address List',
        },
        {
          value: 'firewall_nat',
          title: 'NAT Rules',
        },
      ],
      fieldsLicence: [
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'number',
          label: 'Licence',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'category',
          label: 'Product',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'type',
          label: 'Type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'level',
          label: 'Level',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'masterDistributor',
          hidden: true,
        },
        {
          field: 'created_at',
          label: 'Creation',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Creation',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'activation_at',
          label: 'Activation',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Activation',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'renewal_at',
          label: 'Renewal',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Renewal',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'status',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'licences') && !this.$can('delete', 'licences'),
        },
      ],
      activeWifiOption: '',
      activeNetworkOption: '',
      activeFirewallOption: '',
      siteId: null,
    }
  },
  computed: {
    ...mapState('sites', {
      summary: 'summary',
    }),
    ...mapState('status', {
      summary_hardware: 'items',
    }),
    telephone() {
      if (this.summary.phone_country_iso) {
        const phoneNumber = parsePhoneNumber(this.summary.phone_number, this.summary.phone_country_iso)
        return phoneNumber.number
      }
      return `${this.summary.phone_number}`
    },
  },
  watch: {
    activeTab() {
      console.log('activeTab', this.activeTab)
      this.handleChangeActiveTab(this.activeTab)
    },
    summary() {
      try {
        if (this.summary.name) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')

          pageTitle.innerHTML = `(${this.summary.name})`
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    isAccessPointUnlicensed(newValue) {
      if (newValue) {
        this.$refs.unlicensedAccessPoint.show()
      }
    },
    activeWifiOption(newOption) {
      this.handleChangeTab(newOption)
    },
    activeNetworkOption(newOption) {
      this.handleChangeTab(newOption)
    },
    activeFirewallOption(newOption) {
      this.handleChangeTab(newOption)
    },
    controllerLicense(newVal) {
      if (newVal.need_show_expire_notification) {
        this.$refs.expireSoonModal.show()
      }
    },
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  async created() {
    this.setSelectedTabs()
    this.siteId = this.$route.params.id
    this.loading = true
    if (this.siteId) {
      this.fetchSummary(this.siteId)
    } else {
      this.$router.push({ name: 'error-404' })
    }
    await this.fetchControllerLicence()
    this.getWireguardInfo()
  },
  methods: {
    ...mapActions('sites', [
      'getSiteSummary',
      'getSiteSummaryHardwares',
      'deleteItem',
    ]),
    ...mapActions('status', [
      'updateStatuses',
    ]),
    async fetchControllerLicence() {
      try {
        const { data: { data: controllerLicenseRes } } = await axios.get(`/site/${this.siteId}/controller-license`)
        this.controllerLicense = {
          ...controllerLicenseRes,
          renewal_date: new Date(controllerLicenseRes.renewal_date).toDateString(),
          iconType: this.getStatusColor(controllerLicenseRes.status),
        }
        if (this.controllerLicense.id === null) {
          this.isLicenceExpired = true
          return
        }
        const renewalDate = new Date(controllerLicenseRes.renewal_date)
        const today = new Date()
        const diffTime = Math.abs(renewalDate - today)
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
        if (Number.isInteger(diffDays)) {
          if (diffDays < 10 && diffDays > 1) {
            this.controllerLicense.diffDays = `${diffDays} days left`
          }
          if (diffDays <= 1) {
            this.controllerLicense.diffDays = 'Today'
          }
        }
        const expired = controllerLicenseRes.status === 'expired' ? controllerLicenseRes.number : false
        this.isLicenceExpired = expired
        if (expired) this.$refs.licenceExpired.show()
      } catch (error) {
        console.warn('error: controllerLicenseRes', error)
      }
    },
    handleChangeActiveTab(tab) {
      const { name } = this.$route
      const { id } = this.$route.params
      let subTab

      if (this.activeWifiOption || this.activeNetworkOption || this.activeFirewallOption) {
        subTab = this.activeWifiOption || this.activeNetworkOption || this.activeFirewallOption
      }

      if (subTab && tab === 0) {
        const wifiOptionsValues = this.wifiOptions.map(el => el.value)
        const networkOptionsValues = this.networkOptions.map(el => el.value)
        const firewallOptionsValues = this.firewallOptions.map(el => el.value)

        if (wifiOptionsValues.includes(subTab)) {
          setTimeout(() => {
            this.activeTab = 4
          }, 10)
          return
        } if (networkOptionsValues.includes(subTab)) {
          setTimeout(() => {
            this.activeTab = 2
          }, 10)
          return
        } if (firewallOptionsValues.includes(subTab)) {
          setTimeout(() => {
            this.activeTab = 3
          }, 10)
          return
        }
      }

      if (!subTab) {
        switch (tab) {
          case 2:
            subTab = 'lan_settings'
            this.activeNetworkOption = subTab
            return
          case 3:
            subTab = 'firewall_address_lists'
            this.activeFirewallOption = subTab
            return
          case 4:
            subTab = 'settings'
            this.activeWifiOption = subTab
            return

          default:
            break
        }
      }
      console.log('subInfo post', {
        subTab,
        tab,
      })

      this.$router.push({ name, params: { id }, query: { tab, subTab } })
    },
    handleChangeTab(subTab) {
      const { name } = this.$route
      const { id } = this.$route.params

      this.$router.push({ name, params: { id }, query: { tab: this.activeTab, subTab } })
    },
    setSelectedTabs() {
      const { query } = this.$route
      console.log('set tab', {
        query,
        tabToSet: query.tab,
      })
      this.activeTab = +query.tab
      if (query.subTab) {
        if (this.activeTab === 4) {
          this.activeWifiOption = query.subTab
        } else if (this.activeTab === 2) {
          this.activeNetworkOption = query.subTab
        } else if (this.activeTab === 3) {
          this.activeFirewallOption = query.subTab
        }
      }
      console.log('created', {
        activeTab: this.activeTab,
        subTab: query.subTab,
      })
    },
    ...mapMutations({
      resetUserState: 'sites/RESET_STATE',
      resetBlockState: 'blockList/RESET_STATE',
      resetDhcpState: 'dhcp/RESET_STATE',
      resetHardwareState: 'hardware/RESET_STATE',
    }),
    closeExpireModal() {
      this.$refs.licenceExpired.hide()
    },
    async closeIsExpiredSoon() {
      try {
        await axios.put(`/site/${this.siteId}/license/showed-notification/${this.controllerLicense.id}`)
        this.$refs.expireSoonModal.hide()
      } catch (err) {
        console.warn('error: closeIsExpiredSoon', err)
      }
    },
    closeUnlinccedAccessPoint() {
      this.$refs.unlicensedAccessPoint.hide()
    },
    openUnlinccedAccessPoint() {
      this.$refs.unlicensedAccessPoint.show()
    },
    async getWireguardInfo() {
      try {
        const { data: { data: publicIPRes } } = await axios.get(`/site/${this.siteId}/wire-guard-license`)
        this.publicIP = {
          ...publicIPRes,
          renewal_date: new Date(publicIPRes.renewal_date).toDateString(),
          iconType: this.getStatusColor(publicIPRes.status),
        }
      } catch (error) {
        console.warn('error: publicIPRes', error)
      }
    },
    async switchEnable(isOn) {
      this.isPublicLicenceUpdating = true
      const axiosRequest = isOn ? axios.post : axios.delete

      try {
        await axiosRequest(`/site/${this.siteId}/toggle-wire-guard-public-ip`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Public IP Licence is ${isOn ? 'enabled' : 'disabled'} successfully`,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.warn(error)
      } finally {
        this.getWireguardInfo()
        this.isPublicLicenceUpdating = false
      }
    },
    getStatusColor(statusId) {
      let colorVariant
      switch (statusId) {
        case 'un-allocated':
          colorVariant = 'info'
          break
        case 'allocated':
          colorVariant = 'warning'
          break
        case 'activated':
          colorVariant = 'success'
          break
        case 'renewed':
          colorVariant = 'primary'
          break
        case 'ceasing':
          colorVariant = 'secondary'
          break
        case 'expired':
          colorVariant = 'danger'
          break
        default:
          colorVariant = 'danger'
      }
      return colorVariant
    },
    toEdit(id) {
      this.$router.push({ name: this.uppdateRouteName, params: { id } })
    },
    expand(card) {
      this.expandedCard[card] = !this.expandedCard[card]
    },
    async reloadHardwaresStatuses() {
      try {
        this.isStatusesUpdating = true
        await this.updateStatuses(this.siteId)
        await this.getSiteSummary(this.siteId)
      } catch (error) {
        const { status } = error.response

        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }

        if (status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isStatusesUpdating = false
      }
    },
    async deleteRow(id) {
      try {
        await this.deleteItem(id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'sites-list' })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    resetOptions(type) {
      if (type === 'network') {
        this.activeWifiOption = ''
        this.activeFirewallOption = ''
      } else if (type === 'firewall') {
        this.activeWifiOption = ''
        this.activeNetworkOption = ''
      } else if (type === 'wifi') {
        this.activeNetworkOption = ''
        this.activeFirewallOption = ''
      } else {
        this.activeWifiOption = ''
        this.activeNetworkOption = ''
        this.activeFirewallOption = ''
      }
    },
    handleLicenceNavigate() {
      this.activeTab = 1
    },
    async fetchSummary(id) {
      try {
        await this.getSiteSummary(id)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.loading = false
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$socket.$unsubscribe) {
      this.$socket.$unsubscribe('interfaceStatsEvent')
    }
    this.resetUserState()
    this.resetBlockState()
    this.resetDhcpState()
    this.resetHardwareState()
    next()
  },
}
</script>

<style lang="scss">
.dropdown{
  button{
    padding: 11px 21px;
    // padding: 0 !important;
    border: none;
    background-color: transparent !important;
    &:focus{
      background-color: transparent !important;
    }
    &:hover{
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
}
.dropdown-toggle{
  color: #5e5873 !important;
}
.dropdown-tab{
  .nav-link{
    padding: 0 !important;
  }
}
.dark-layout{
  .dropdown-toggle{
    color: #d0d2d6  !important;
  }
  .dropdown{
  button{
    &::after{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important
    }
  }
}
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
